import React, { useMemo, useRef, useState } from 'react'
import ReactECharts from "echarts-for-react";
import { Button, Form } from 'antd';
import { sum, sumBy } from 'lodash';

const FractureVisualizer = () => {
  const container = useRef(null)
  const [show, setShow] = useState(false)
  const wellboreLength = Form.useWatch(['Completion', 'well', 'WELLBORE_LEN'])
  const stages = Form.useWatch(['Completion', 'hydraulic_fracture'])
  const numOfStage = Form.useWatch(['Completion', 'well', 'NUMBER_STAGES'])

  const [options, height] = useMemo(() => {
    if (!stages?.length || !show || container?.current?.offsetParent === null) return []
    const stagesData = stages?.slice(0, numOfStage) ?? []
    const secondaryLengthSum = sum(stagesData.map(stage => parseFloat(stage.SECONDARY?.FRACTURE_HALF_LEN || 0) * 2)) * numOfStage
    const stageGap = 20
    const gapSum = stageGap * (numOfStage - 1)
    const actualWellboreLineWidth = secondaryLengthSum + gapSum
    const containerWidth = (container.current?.clientWidth ?? actualWellboreLineWidth) - 100
    const wellboreLineWidth = actualWellboreLineWidth < containerWidth ? actualWellboreLineWidth : containerWidth
    const scale = wellboreLineWidth / actualWellboreLineWidth
    const barWidth = 20 * scale
    let maxY = 0

    const wellboreLine = {
      value: wellboreLength,
      symbol: 'rect',
      symbolSize: [wellboreLineWidth, barWidth],
      symbolOffset: [0, 10],
    }

    const stagesLines = stagesData.map((stage, index) => {
      const stageLines = {}
      const primaryHaftLength = parseFloat(stage.PRIMARY?.FRACTURE_HALF_LEN || 0)
      const secondaryHalfLength = parseFloat(stage.SECONDARY?.FRACTURE_HALF_LEN || 0) * scale
      const sumSecondaryLengthTillNow = (sumBy(stagesData.slice(1, index + 1), 'SECONDARY.FRACTURE_HALF_LEN') * 2 + stageGap) * scale

      if (primaryHaftLength > maxY) maxY = primaryHaftLength

      stageLines.primaryFractureLine = {
        type: 'pictorialBar',
        name: `Stage ${ index + 1 }: Primary Fracture`,
        emphasis: {
          disabled: true,
          scale: false
        },
        label: {
          show: false,
        },
        data: [
          {
            value: primaryHaftLength * 2,
            symbol: 'rect',
            z: 0,
            symbolSize: [barWidth, primaryHaftLength * 2 * scale],
            symbolOffset: [wellboreLineWidth / 2 - (index ? sumSecondaryLengthTillNow : 0) - (stageGap * index) - (index === 0 ? -stageGap : 0) - stageGap * 2, primaryHaftLength * scale],
            animationDelay: function (dataIndex, params) {
              return (index + 1) * 400;
            }
          }
        ],
      }

      const numOfSecondaryLines = 2 * Math.floor(((primaryHaftLength * 2) / 50) / 2)

      const secondaryDataLines = new Array(numOfSecondaryLines).fill(null)

      stageLines.secondaryLines = {
        type: 'pictorialBar',
        name: `Stage ${ index + 1 }: Secondary Fracture`,
        emphasis: {
          disabled: true,
          scale: false
        },
        label: {
          show: false,
        },
        data: secondaryDataLines.map((_, secondaryIndex) => {
          const yOffsetGap = (() => {
            const currentLineNum = secondaryIndex + 1
            if (currentLineNum <= secondaryDataLines.length / 2) {
              return primaryHaftLength * scale - 50 * scale * secondaryIndex
            }

            return -(primaryHaftLength * scale - (50 * scale * (secondaryIndex - (secondaryDataLines.length / 2)))) + 20 * scale * 0.6
          })()

          return {
            value: secondaryHalfLength * 2,
            symbol: 'rect',
            z: 1,
            symbolSize: [secondaryHalfLength * 2 * scale, barWidth * 2 / 3],
            symbolOffset: [(wellboreLineWidth / 2 - (index ? sumSecondaryLengthTillNow : 0) - (stageGap * index)) - (index === 0 ? -stageGap : 0) - (secondaryIndex ? 0.82 * scale * secondaryIndex : 0) - stageGap * 2, yOffsetGap],
            animationDelay: function (dataIndex, params) {
              return (index + 1) * 400;
            }
          }
        })
      }
      return stageLines
    }) || []

    const _options = {
      backgroundColor: 'transparent',
      tooltip: {},
      legend: {
        textStyle: { color: '#ddd' }
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: '100px',
        containLabel: true,
      },
      xAxis: [
        {
          data: [],
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            show: false
          },
          min: -(secondaryLengthSum + gapSum + 60),
          max: secondaryLengthSum + gapSum + 60,
        }
      ],
      yAxis: {
        splitLine: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
        min: -maxY,
        max: maxY
      },
      markLine: {
        z: -1
      },
      animationEasing: 'elasticOut',
      series: [
        {
          type: 'pictorialBar',
          name: 'Wellbore Length',
          animation: false,
          emphasis: {
            disabled: true,
            scale: false
          },
          label: {
            show: false,
          },
          data: [
            wellboreLine
          ],
        },
        ...stagesLines.reverse().flatMap((stageLines) => {
          return [stageLines.primaryFractureLine, stageLines.secondaryLines]
        })
      ]
    };

    return [_options, maxY * 2]
  }, [numOfStage, show, stages, wellboreLength])


  return (
    <div ref={container} className="w-full h-full flex flex-col gap-4">
      <div>
        <Button htmlType="button" onClick={() => {
          setShow(false)
          setTimeout(() => {
            setShow(true)
          }, 100);
        }}>Generate Data</Button>
      </div>
      {options && show ? (
        <ReactECharts option={options} style={{
          height: height * 1.2 + 100
        }} />
      ) : null}
    </div>
  )
}

export default FractureVisualizer