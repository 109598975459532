import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ProjectSettingController from './components/ProjectSettingController'
import { Button, ConfigProvider, Form, Steps, message } from 'antd'
import { get, isEqual, isNil, merge, omit } from 'lodash-es'
import enUS from "antd/locale/en_US";
import classNames from 'classnames'
import GeneralSetting from './components/GeneralSetting'
import ReservoirSetting from './components/ReservoirSetting'
import PVTSetting from './components/PVTSetting'
import SCALSetting from './components/SCALSetting'
import InitialSetting from './components/InitialSetting'
import WellSetting from './components/WellSetting';
import HydraulicFractureSetting from './components/HydraulicFractureSetting';
import * as yup from 'yup'
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCOProjectSettings, upsertProjectSettings } from 'service/completionOptimizer';
import Loading from 'components/common/Loading';
import { useLocalStorage } from '@uidotdev/usehooks';
import { yupValidator } from 'utils/form';

import classes from './ProjectSettings.module.scss'

const initialValues = () => ({
  GENERAL: {
    unit: 'FIELD',
    fluid: 'BLACK_OIL',
    reservoir: 'Singleperm',
    neuralnet: 'Default'
  },
  RESERVOIR: {
    matrix: Array(31).fill(null).map((_, index) => {
      return {
        REGION: index === 0 ? 'All' : `K=${ index }${ index === 16 ? ' (perf zone)' : '' }`,
        POROSITY: null,
        PERMEABILITY: null,
        EFFECTIVE_THICKNESS: null
      }
    }),
    fracture: Array(31).fill(null).map((_, index) => {
      return {
        REGION: index === 0 ? 'All' : `K=${ index }${ index === 16 ? ' (perf zone)' : '' }`,
        POROSITY: null,
        PERMEABILITY: null,
        EFFECTIVE_THICKNESS: null
      }
    }),
  },
  INITIAL: Array(31).fill(null).map((_, index) => {
    return {
      REGION: index === 0 ? 'All' : `Layer ${ index }`,
      PRESSURE: null,
      SATGAS: null,
      SATWATER: null,
      RS: null
    }
  }),
})

const stepValidators = {
  SCAL: (form) => {
    const errors = []

    const formValues = form.getFieldsValue(true)

    const matrixSwoTableValues = get(formValues, 'SCAL.MATRIX.SWO') ?? []
    const matrixSgoTableValues = get(formValues, 'SCAL.MATRIX.SGO') ?? []

    const matrixSwoKroValues = matrixSwoTableValues.map(row => row.KRO)
    const matrixSgoKroValues = matrixSgoTableValues.map(row => row.KRO)

    if (!isEqual(matrixSwoKroValues, matrixSgoKroValues)) {
      errors.push(`Validation error in SCAL > Matrix: Values of Kro in SWO and SGO tables must be the same`)
    }

    const fractureSwoTableValues = get(formValues, 'SCAL.FRACTURE.SWO') ?? []
    const fractureSgoTableValues = get(formValues, 'SCAL.FRACTURE.SGO') ?? []

    const fractureSwoKroValues = fractureSwoTableValues.map(row => row.KRO)
    const fractureSgoKroValues = fractureSgoTableValues.map(row => row.KRO)

    if (!isEqual(fractureSwoKroValues, fractureSgoKroValues)) {
      errors.push(`Validation error in SCAL > Fracture: Values of Kro in SWO and SGO tables must be the same`)
    }

    return errors
  }
}

const ProjectSettingsPage = ({
  values,
  projectId,
  backupId
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm()

  const reservoirType = Form.useWatch(['GENERAL', 'reservoir'], form)

  const schema = yup.object({
    GENERAL: yup.object({
      title: yup.string().required('Project title is required'),
      start: yup.lazy((value) => typeof value === 'string' ? yup.string().required('Simulation start date is required') : yup.object().required('Simulation start date is required')),
      unit: yup.string().required('Simulation Unit is required'),
      fluid: yup.string().required('Fluid Type is required'),
      reservoir: yup.string().required('Reservoir Type is required'),
      neuralnet: yup.string().required('Neural Net Selection is required')
    }),
    RESERVOIR: yup.object({
      matrix: yup.array(yup.object({
        POROSITY: yup.string().test({
          test: (value) => {
            if (!value) return true
            const valueNum = parseFloat(value)
            if (valueNum < 0 || valueNum > 1) return false
            return true
          },
          message: '[Matrix] Porosity value must be >= 0 and <= 1'
        }).nullable(),
        PERMEABILITY: yup.string().test({
          test: (value) => {
            if (!value) return true
            const valueNum = parseFloat(value)
            if (valueNum < 0) return false
            return true
          },
          message: '[Matrix] Porosity value must be >= 0'
        }).nullable(),
        EFFECTIVE_THICKNESS: yup.string().test({
          test: (value) => {
            if (!value) return true
            const valueNum = parseFloat(value)
            if (valueNum < 0) return false
            return true
          },
          message: '[Matrix] Effective thickness value must be >= 0'
        }).nullable()
      })).test({
        test: (value) => {
          const recordAll = value[0]
          if (!isNil(recordAll?.POROSITY) && !isNil(recordAll?.PERMEABILITY) && !isNil(recordAll.EFFECTIVE_THICKNESS)) {
            return true
          }
          const otherRecords = value.slice(1)
          if (otherRecords.some(record => {
            return isNil(record.POROSITY) || isNil(record.PERMEABILITY) || isNil(record.EFFECTIVE_THICKNESS)
          })) {
            return false
          }
        },
        message: '[Matrix] records must have values'
      }),
      fracture: yup.array(yup.object({
        POROSITY: yup.string().test({
          test: (value) => {
            if (!value) return true
            const valueNum = parseFloat(value)
            if (valueNum < 0 || valueNum > 1) return false
            return true
          },
          message: '[Fracture] Porosity value must be >= 0 and <= 1'
        }).nullable(),
        PERMEABILITY: yup.string().test({
          test: (value) => {
            if (!value) return true
            const valueNum = parseFloat(value)
            if (valueNum < 0) return false
            return true
          },
          message: '[Fracture] Porosity value must be >= 0'
        }).nullable(),
        EFFECTIVE_THICKNESS: yup.string().test({
          test: (value) => {
            if (!value) return true
            const valueNum = parseFloat(value)
            if (valueNum < 0) return false
            return true
          },
          message: '[Fracture] Effective thickness value must be >= 0'
        }).nullable()
      })).test({
        test: (value, ctx) => {
          if (reservoirType === 'Singleperm') return true
          if (!value) return false
          const recordAll = value[0]
          if (!isNil(recordAll?.POROSITY) && !isNil(recordAll?.PERMEABILITY) && !isNil(recordAll.EFFECTIVE_THICKNESS)) {
            return true
          }
          const otherRecords = value.slice(1)
          if (otherRecords.some(record => {
            return isNil(record.POROSITY) || isNil(record.PERMEABILITY) || isNil(record.EFFECTIVE_THICKNESS)
          })) {
            return false
          }
        },
        message: '[Fracture] Records must have values'
      })
    }),
    PVT: yup.object({
      density: yup.object({
        oil: yup.object({
          value: yup.number().required('[PVT] Oil Density is required')
        }),
        water: yup.object({
          value: yup.number().required('[PVT] Water Density is required')
        }),
        gas: yup.object({
          value: yup.number().required('[PVT] Gas Density is required')
        }),
      }).required('[PVT] Density values are required'),
      rock: yup.object({
        reference_pressure: yup.object({
          value: yup.number().required('[PVT] Reference Pressure is required')
        }),
        rock_compressibility: yup.object({
          value: yup.number().required('[PVT] Rock Compressibility is required')
        }),
      }).required('[PVT] Rock values are required'),
      water: yup.object({
        pref: yup.object({
          value: yup.number().required('[PVT] Reference Pressure (Pref) is required')
        }),
        water_fvf_at_pref: yup.object({
          value: yup.number().required('[PVT] Water FVF at Pref is required')
        }),
        water_compressibility: yup.object({
          value: yup.number().required('[PVT] Water Compressibility is required')
        }),
        water_viscosity_at_pref: yup.object({
          value: yup.number().required('[PVT] Water viscosibility at Pref at Pref is required')
        }),
        water_viscosity: yup.object({
          value: yup.number().required('[PVT] Water viscosibilityis required')
        }),
      }).required('[PVT] Water values are required'),
      gas: yup.array(yup.object({})).min(2, '[PVT] Gas must have 2 or more records').required('[PVT] Gas values are required'),
      oil: yup.array(yup.object({})).min(1, '[PVT] Oil values are required').required('[PVT] Oil values are required'),
    }).required('PVT values are required'),
    SCAL: yup.object({
      matrix: yup.object({
        SGO: yup.array(yup.object()).required('[SCAL] Matrix SGO table must have values'),
        SWO: yup.array(yup.object()).required('[SCAL] Matrix SWO table must have values')
      }).required('[SCAL] Matrix must have values'),
      fracture: yup.object({
        SGO: yup.array(yup.object()).required('[SCAL] Fracture SGO table must have values'),
        SWO: yup.array(yup.object()).required('[SCAL] Fracture SWO table must have values')
      }).required('[SCAL] Fracture must have values')
    }).required('SCAL values are required'),
    INITIAL: yup.array(yup.object()).test({
      test: (value) => {
        const recordAll = value[0]
        if (!isNil(recordAll?.PRESSURE) && !isNil(recordAll?.SATGAS) && !isNil(recordAll.SATWATER) && !isNil(recordAll.RS)) {
          return true
        }
        const otherRecords = value.slice(1)
        if (otherRecords.some(record => {
          return isNil(record.PRESSURE) || isNil(record.SATGAS) || isNil(record.SATWATER) || isNil(record.RS)
        })) {
          return false
        }
      },
      message: '[Initial] Records must have values'
    }),
    Completion: yup.object({
      well: yup.object({
        WELL_NAME: yup.string().required('Well name is required'),
        WELLBORE_LEN: yup.number().test({
          test: (value) => value > 0,
          message: 'Wellbore Length must be > 0'
        }).required('Wellbore Length is required'),
        NUMBER_STAGES: yup.number().integer('Number of stages must be an integer').test({
          test: (value) => value > 0,
          message: 'Number of stages must be > 0'
        }).required('Number of stages is required'),
        WELLBORE_DIAMETER: yup.number().test({
          test: (value) => value > 0 && value < 100,
          message: 'Wellbore diameter must be > 0 and < 100'
        }).required('Wellbore diameter is required'),
        DATE_INITIAL_PROD: yup.lazy((value) => typeof value === 'string' ? yup.string().required('Date of initial production is required') : yup.object().required('Date of initial production is required')),
      }).required('Well settings are required'),
      hydraulic_fracture: yup.array(yup.object({
        STAGE_LEN: yup.number().test({
          test: (value) => value > 0,
          message: 'Stage Length must be > 0'
        }).required('Stage Length is required'),
        SAND_USAGE: yup.number().test({
          test: (value) => value > 0,
          message: 'Stage Usage must be > 0'
        }).required('Stage Usage is required'),
        NUMBER_CLUSTERS: yup.number().integer('Number of clusters must be an integer').test({
          test: (value) => value > 0,
          message: 'Number of clusters must be > 0'
        }).required('Number of clusters is required'),
        PRIMARY: yup.object({
          FRACTURE_HALF_LEN: yup.number().test({
            test: (value) => value > 0 && value < 1000,
            message: 'Primary Fracture Half length must be > 0 and < 1000'
          }).required('Primary Fracture Half length is required'),
          VERTICAL_PENETRATION: yup.number().test({
            test: (value) => value > 0 && value <= 500,
            message: 'Primary Vertical Penetration must be > 0 and <= 500'
          }).required('Primary Vertical Penetration is required'),
          FRACTURE_WIDTH: yup.number().test({
            test: (value) => value > 0 && value <= 2,
            message: 'Primary Fracture width must be > 0 and <= 2'
          }).required('Primary Fracture width is required'),
        }),
        SECONDARY: yup.object({
          FRACTURE_HALF_LEN: yup.number().test({
            test: (value, ctx) => {
              return value > 0
            },
            message: 'Secondary Fracture Half length must be > 0'
          }).required('Secondary Fracture Half length is required'),
          VERTICAL_PENETRATION: yup.number().test({
            test: (value) => value > 0 && value <= 500,
            message: 'Secondary Vertical Penetration must be > 0 and <= 500'
          }).required('Secondary Vertical Penetration is required'),
          FRACTURE_WIDTH: yup.number().test({
            test: (value) => value > 0 && value <= 2,
            message: 'Secondary Fracture width must be > 0 and <= 2'
          }).required('Secondary Fracture width is required'),
        })
      })).required('Stages settings are required')
    })
  })

  const validateSync = yupValidator(schema, form.getFieldsValue);

  const [draftValues, saveDraft] = useLocalStorage('co.projectSettings.draft')

  const { mutate: upsertSettings, isPending } = useMutation({
    mutationFn: (data) => upsertProjectSettings({
      data: {
        ...merge(values, data),
        project_id: projectId,
        backup_id: backupId
      }
    })
  })

  const steps = useMemo(
    /**
     * 
     * @returns {import('antd').StepProps[]}
     */
    () => {
      return [
        {
          key: 'GENERAL',
          title: 'General',
          content: <GeneralSetting />,
          disabled: true
        },
        {
          key: 'RESERVOIR',
          title: 'Reservoir',
          content: <ReservoirSetting />,
          disabled: true
        },
        {
          key: 'PVT',
          title: 'PVT',
          content: <PVTSetting />,
          disabled: true
        },
        {
          key: 'SCAL',
          title: 'SCAL',
          content: <SCALSetting />,
          disabled: true
        },
        {
          key: 'INITIAL',
          title: 'Initial',
          content: <InitialSetting />,
          disabled: true
        },
        {
          key: 'Completion.well',
          title: 'Well',
          content: <WellSetting />,
          disabled: true
        },
        {
          key: 'Completion.hydraulic_fracture',
          title: 'Hydraulic Fracture',
          content: <HydraulicFractureSetting />,
          disabled: true
        }
      ]
    }, [])

  const onSubmit = useCallback((data) => {
    try {
      validateSync()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.forEach(errMsg => {
          messageApi.error({
            content: errMsg,
          })
        })
      } else throw error
      return
    }
    let hasError = false
    data.Completion?.hydraulic_fracture?.forEach((stage, index) => {
      const stageLength = stage.STAGE_LEN
      const maxSecondaryFHLValue = stageLength / 2
      if (stage.SECONDARY.FRACTURE_HALF_LEN > maxSecondaryFHLValue) {
        hasError = true
        messageApi.error({
          content: `Stage ${ index + 1 } Fracture Half length must be <= ${ maxSecondaryFHLValue }`,
        })
      }
    })
    if (hasError) return;
    upsertSettings({
      ...data,
      Completion: {
        ...data.Completion,
        hydraulic_fracture: data.Completion.hydraulic_fracture.map(stage => {
          const stageData = {
            ...stage
          }

          delete stageData['__synced']
          return stageData
        })
      }
    })
  }, [messageApi, upsertSettings, validateSync])

  const onNext = useCallback(async () => {
    const currentStepInfo = steps[currentStep]
    const validator = stepValidators[currentStepInfo.key]

    if (validator) {
      const errors = validator(form)
      if (errors && errors.length) {
        errors.forEach(errMsg => {
          messageApi.error({
            content: errMsg
          }, 5000)
        })
        return
      }
    }

    try {
      validateSync(currentStepInfo.key)
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.forEach(errMsg => {
          messageApi.error({
            content: errMsg,
          })
        })
      } else throw error
      return
    }

    setCurrentStep(current => {
      if (current === steps.length - 1) return current
      return current + 1
    })
  }, [currentStep, form, messageApi, steps, validateSync])

  const onSaveDraft = useCallback(() => {
    const values = form.getFieldsValue(true)
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(values));
    var dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "draft.json");
    dlAnchorElem.click();
    dlAnchorElem.remove()
    // saveDraft(values)
  }, [form])

  const onTriggerImportDraft = useCallback(() => {
    document.getElementById('draftFileInput')?.click()
  }, [])

  const onUploadDraft = useCallback((event) => {
    const file = event.target.files[0];
    if (!file) return
    const reader = new FileReader();

    reader.onload = () => {
      const content = reader.result;
      try {
        const draftJSON = JSON.parse(content)
        form.setFieldsValue(draftJSON)
      } catch (error) {
        console.error(error)
      }
    };

    reader.readAsText(file);
  }, [form])

  const onBack = useCallback(() => {
    setCurrentStep(current => {
      if (current === 0) return current
      return current - 1
    })
  }, [])

  const defaultValues = useMemo(() => projectId ? merge(initialValues(), values) : merge(initialValues(), draftValues), [projectId, values, draftValues])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      {contextHolder}
      <Form
        form={form}
        className={classNames('vstack gap-4 px-4 py-2 overflow-y-scroll h-100', classes.form)}
        layout="vertical"
        labelAlign="left"
        onFinish={onSubmit}
        initialValues={defaultValues}
      >
        <ProjectSettingController>
          <div className="w-100">
            <Steps
              type="navigation"
              current={currentStep}
              onChange={setCurrentStep}
              className={classes.steps}
              size="small"
              items={steps.map(step => omit(step, 'content'))}
              progressDot
            />
          </div>
          {steps.map((step, stepIndex) => (
            <div
              key={step.key}
              className={classNames('mt-4', {
                'd-none': stepIndex !== currentStep
              })}
            >
              {step.content}
            </div>
          ))}
        </ProjectSettingController>
        <div className="flex justify-content-center gap-4">
          {currentStep < steps.length - 1 ? (
            <div className="hstack justify-content-center gap-2">
              {currentStep > 0 ? (
                <Button htmlType="button" onClick={onBack}>Back</Button>
              ) : null}
              {!projectId ? (
                <>
                  <Button htmlType="button" onClick={onTriggerImportDraft}>
                    Import draft
                  </Button>
                  <input id="draftFileInput" onChange={onUploadDraft} type="file" accept="application/json" hidden />
                </>
              ) : null}
              <Button htmlType="button" onClick={onSaveDraft}>Save draft</Button>
              <Button htmlType="button" onClick={onNext}>Next</Button>
            </div>
          ) : null}
          <div className={classNames('hstack justify-content-center gap-2', {
            'd-none': currentStep < steps.length - 1
          })}>
            <Button htmlType="button" onClick={onBack} loading={isPending}>Back</Button>
            <Button htmlType="submit" loading={isPending}>Save</Button>
          </div>
        </div>
      </Form>
    </ConfigProvider>
  )
}

const ProjectSettingsPageContainer = () => {
  const [searchParams] = useSearchParams()
  const [projectId, backupId] = useMemo(() => [searchParams.get('projectId'), searchParams.get('backupId')], [searchParams])
  const [showForm, setShowForm] = useState(true)

  const { data, isFetching } = useQuery({
    queryKey: ['projectSettings', projectId, backupId],
    queryFn: () => getCOProjectSettings(projectId, backupId),
    enabled: Boolean(projectId && backupId)
  })

  useEffect(() => {
    setShowForm(false)
    setTimeout(() => setShowForm(true), 500)
  }, [projectId])

  if (isFetching) return <Loading />

  if (!showForm) return null

  return <ProjectSettingsPage projectId={projectId} backupId={backupId} values={projectId ? data?.data : null} />
}

export default ProjectSettingsPageContainer